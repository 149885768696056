import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    // background: #000000;
    ${ColumnFlex}
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
    // @media (min-width: 992px) {
    //     width: 700px;
    // }
`
export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
`

export const CloseContainer = styled.div`
    ${RowFlex}
    justify-content: flex-start;
    margin-left: 10px;
    width: 100%;
    margin-top: 2px;
    cursor: pointer;
`

export const CloseIcon = styled.img`
    width: 16px;
    height: 16px;
`

export const NoiceLogo = styled.img`
    width: 100px;
    height: 28.15px;
    margin-top: 15%;
`
export const LivestreamEllipse = styled.div`
    ${RowFlex}
    ${FlexCentered}
    width: 180px;
    height: 180px;

    border: 2px solid #fad810;
    box-sizing: border-box;
    border-radius: 195px;
    margin-top: 7.5%;
`

export const ContentImage = styled.img`
    width: 180px;
    /* height: 180px; */
    height: auto;
    border-radius: 4px;
    margin-top: 7.5%;
    object-fit: cover;
    ${({ isStretched }) =>
        isStretched &&
        css`
            height: 208px;
            width: 156px;
        `}
    ${({ isLivestream }) =>
        isLivestream &&
        css`
            width: 164.21px;
            height: 164.21px;
            border-radius: 195px;
            margin-top: unset;
        `}
`

export const DownloadText = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
`

export const DownloadButtonContainer = styled.div`
    // width: 49.72%;
    // height: 8.75vh;
    background: #fad810;
    border-radius: 4px;
    ${RowFlex}
    ${FlexCentered}
    margin-top: 3.75%;
    cursor: pointer;
    margin: 24px 0px;
    // width: 178px;
    height: 56px;
    padding: 21px 48px;
`

export const DownloadButtonText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #1e222c;
`

export const DownloadTextBold = styled.div`
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
`

export const DownloadTextContainer = styled.div`
    ${ColumnFlex}
    ${FlexCentered}
    margin-top: 7.5%;
`
